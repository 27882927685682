@use 'sass:color';

@use 'variables' as v;

$spacers: (
  0: 0,
  1: 1rem * 0.25,
  2: 1rem * 0.5,
  3: 1rem,
  4: 1rem * 1.5,
  5: 1rem * 3,
  6: 1rem * 4.5,
);

@use 'bootstrap/scss/bootstrap' as b with (
  $enable-rounded: false,
  $enable-negative-margins: true,

  $primary: v.$primary,
  $light: v.$light,

  $font-family-sans-serif: monospace,

  $border-color: v.$border-color,
  $input-border-color: v.$border-color,
  $card-border-color: v.$border-color,
  $list-group-border-color: v.$border-color,

  $navbar-dark-color: white,

  $focus-ring-width: 0,
  $input-focus-border-color: v.$primary,

  $modal-dialog-margin-y-sm-up: 3rem,
  $modal-backdrop-bg: white,
  $modal-backdrop-opacity: 0.95,
  $modal-content-border-width: 0,
  $modal-md: 500px,
  $modal-lg: 1000px,
  $modal-xl: 1500px,
  $modal-fade-transform: scale(0),
  $modal-show-transform: none,
  $modal-transition: transform 0.15s ease-out,
  $modal-content-bg: none,

  $spacers: $spacers,

  $utilities: (
    fill: (
      property: fill,
      values: (
        white: white,
        black: black,
        light: v.$light,
      ),
    ),
    white-space: (
      property: white-space,
      values: (
        pre-line: pre-line,
      ),
    ),
    pointer-events: (
      property: pointer-events,
      values: (
        none: none,
        auto: auto,
      ),
    ),
    cursor: (
      property: cursor,
      values: (
        default: default,
        pointer: pointer,
      ),
    ),
  )
);

@use '@ng-select/ng-select/scss/default.theme' as s with (
  $ng-select-bg: b.$body-bg,

  $ng-select-primary-text: b.$body-color,
  $ng-select-input-text: b.$body-color,
  $ng-select-dropdown-option-text: b.$body-color,
  $ng-select-dropdown-optgroup-text: b.$body-color,
  $ng-select-placeholder: b.$input-placeholder-color,
  $ng-select-dropdown-option-disabled: b.$gray-600,

  $ng-select-selected: color.adjust(b.$primary, $lightness: -20%),
  $ng-select-marked: color.adjust(b.$dark, $lightness: 10%),
  $ng-select-border: b.$border-color,

  $ng-select-height: 35px,
  $ng-select-border-radius: 0,
  $ng-select-value-padding-left: 0.5rem
);

html,
body {
  width: 100%;
  height: 100%;
}

:focus-visible {
  outline: none;
}

::selection {
  background-color: b.$secondary;
}

label {
  padding: 7px 0;
  white-space: nowrap;
  color: black;

  &.required {
    &::after {
      content: ' *';
      color: b.$danger;
    }
  }
}

textarea {
  resize: none;

  &.font-monospace {
    font-size: 0.8rem;
  }
}

.form-control {
  &.ng-invalid {
    &.ng-touched,
    &.ng-dirty {
      border-color: b.$danger;

      & + .invalid-feedback {
        display: block;
      }
    }
  }
}

.modal-dialog {
  @extend .modal-dialog-centered;

  .modal-content {
    background: none;
    pointer-events: none;

    input,
    textarea,
    label,
    a,
    button {
      pointer-events: auto;
    }
  }
}

.modal-header {
  button {
    color: b.$gray-500;

    &:focus {
      box-shadow: none;
    }
  }
}

.position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.polygon-0 {
  clip-path: polygon(
    0% var(--polygon-size, 20px),
    100% var(--polygon-size, 20px),
    calc(100% - var(--polygon-size, 20px)) 100%,
    var(--polygon-size, 20px) 100%
  );
}
.polygon-1 {
  clip-path: polygon(
    0% var(--polygon-size, 20px),
    calc(100% - var(--polygon-size, 20px)) 0%,
    calc(100% - var(--polygon-size, 20px)) 100%,
    0% calc(100% - var(--polygon-size, 20px))
  );
}
.polygon-2 {
  clip-path: polygon(
    0% var(--polygon-size, 20px),
    calc(100% - var(--polygon-size, 20px)) 0%,
    calc(100% - var(--polygon-size, 20px)) 100%,
    var(--polygon-size, 20px) 100%
  );
}
.polygon-3 {
  clip-path: polygon(
    0% var(--polygon-size, 20px),
    calc(100% - var(--polygon-size, 20px)) 0%,
    100% calc(100% - var(--polygon-size, 20px)),
    var(--polygon-size, 20px) 100%
  );
}
.polygon-4 {
  clip-path: polygon(
    5% 0%,
    100% var(--polygon-size, 20px),
    calc(100% - var(--polygon-size, 20px)) 100%,
    0% calc(100% - var(--polygon-size, 20px))
  );
}
.polygon-5 {
  clip-path: polygon(
    5% 0%,
    100% var(--polygon-size, 20px),
    100% calc(100% - var(--polygon-size, 20px)),
    var(--polygon-size, 20px) 100%
  );
}
.polygon-6 {
  clip-path: polygon(
    5% 0%,
    calc(100% - var(--polygon-size, 20px)) 0%,
    100% calc(100% - var(--polygon-size, 20px)),
    0% calc(100% - var(--polygon-size, 20px))
  );
}
.polygon-7 {
  clip-path: polygon(
    5% 0%,
    calc(100% - var(--polygon-size, 20px)) 0%,
    100% calc(100% - var(--polygon-size, 20px)),
    var(--polygon-size, 20px) 100%
  );
}

.polygon-small {
  --polygon-size: 5px;
}

.form-control.bg-black {
  &::placeholder {
    color: b.$light;
  }
}
